import * as DOMPurify from "dompurify";
// import { LinkText } from "components/common";

export const articleContentSanitized = (content) => {
  return DOMPurify.sanitize(content, {
    ADD_TAGS: ["iframe", "script"],
    ADD_ATTR: [
      "allow",
      "allowfullscreen",
      "frameborder",
      "scrolling",
      "target",
      "onclick",
    ],
  });

  // // Regular expression to match anchor tags
  // const anchorRegex =
  //   /<a[^>]*href=["']([^"]*)["'][^>]*target=["']([^"]*)["']>(.*?)<\/a>/g;

  // // Replace anchor tags with custom LinkText component
  // const sanitizedContent = content.replace(
  //   anchorRegex,
  //   (match, href, target, text) => {
  //     return `<LinkText href="${href}" target="${target}">${text}AAA</LinkText>`;
  //   }
  // );

  // // Sanitize using DOMPurify or your custom method
  // const sanitizedHtml = DOMPurify.sanitize(sanitizedContent, {
  //   ADD_TAGS: ["iframe", "script"],
  //   ADD_ATTR: [
  //     "allow",
  //     "allowfullscreen",
  //     "frameborder",
  //     "scrolling",
  //     "target",
  //   ],
  // });

  // return sanitizedHtml;
};
