import React, { useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Box, Grid, Stack } from "@mui/material";
import { GetPage } from "utils/api/getContent";
import theme from "utils/theme";
import ArticleContentHTML from "components/articles/ArticleContentHTML";
import { articleContentSanitized } from "utils/content";
import Error404 from "pages/Error404";
import useUserTracking from "utils/useTracking";
import appContext from "utils/context";
import "components/articles/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const PageInfo = () => {
  const { pageSlug } = useParams();

  useUserTracking({
    page: pageSlug,
  });

  const { setIsWhiteContentPage } = useContext(appContext);
  useEffect(() => {
    setIsWhiteContentPage(true);

    return () => {
      setIsWhiteContentPage(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // PAGE
  const { data: pageContent } = GetPage(pageSlug);

  const article = pageContent ? pageContent[0] : undefined;

  const topRef = useRef(null);

  const PageContent = ({ article }) => {
    // console.log("article", article);
    const getArticleContent = () => {
      return article.content;
    };

    return (
      <Box
        sx={{
          padding: {
            xs: theme.spacing(3, 2, 4, 2),
            sm: theme.spacing(3, 4, 4, 4),
          },
          marginX: {
            xs: -2,
            sm: 0,
          },
          borderRadius: "0.75rem",
          position: "relative",
          overflow: "hidden",
          // display: "flex",
          // flexDirection: "column",
          // justifyContent: "center",
          // "&:before": {
          //   content: "''",
          //   position: "absolute",
          //   zIndex: -1,
          //   top: 0,
          //   left: 0,
          //   right: 0,
          //   bottom: 0,
          //   background: "rgba(255, 255, 255, 0.25)",
          //   mixBlendMode: "overlay",
          //   backdropFilter: "blur(40px)",
          //   display: { xs: "none", sm: "block" },
          // },
        }}
      >
        <Stack spacing={2}>
          {/* <Box>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 400,
                lineHeight: 1.3,
                marginBottom: `${theme.spacing(1)} !important`,
                fontSize: {
                  sm: "1.5rem",
                  lg: "2rem",
                },
              }}
            >
              {article.title}
            </Typography>
            {article.subtitle && (
              <Typography
                variant="h2"
                sx={{
                  fontSize: "1.25rem",
                  color: "primary.darker",
                  marginBottom: "0 !important",
                }}
              >
                {article.subtitle}
              </Typography>
            )}
          </Box>
          <Stack
            direction="row"
            alignItems="stretch"
            spacing={2}
            divider={
              <Box
                sx={{
                  width: "0.5px",
                  heigth: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.125)",
                }}
              />
            }
            sx={{
              marginX: `-${theme.spacing(4)} !important`,
              paddingX: 4,
              borderTop: "0.5px solid rgba(255, 255, 255, 0.125)",
              borderBottom: "0.5px solid rgba(255, 255, 255, 0.125)",
              opacity: 0.8,
              background: "rgba(255, 255, 255, 0.10)",
            }}
          >
            {article.speaker && (
              <Box flexGrow={1} paddingY={0.5}>
                <Typography variant="body3" sx={{ color: "primary.darker" }}>
                  Key Opinion Leader:{" "}
                </Typography>
                <Typography variant="body3" sx={{ color: "primary.main" }}>
                  {article.speaker}
                </Typography>
              </Box>
            )}
            {article.publish_date && (
              <Box paddingY={0.5}>
                <Typography
                  variant="body3"
                  sx={{
                    color: "primary.darker",
                    display: { xs: "block", sm: "inline-block" },
                  }}
                >
                  Publicat:
                </Typography>{" "}
                <Typography variant="body3" sx={{ color: "primary.main" }}>
                  {format(new Date(article.publish_date), "dd MMMM yyyy", {
                    locale: ro,
                  })}
                </Typography>
              </Box>
            )}
            {article.duration && (
              <Box paddingY={0.5}>
                <Typography
                  variant="body3"
                  sx={{
                    color: "primary.darker",
                    display: { xs: "block", sm: "inline-block" },
                  }}
                >
                  Timp de lectură:
                </Typography>{" "}
                <Typography variant="body3" sx={{ color: "primary.main" }}>
                  {article.duration}
                </Typography>
              </Box>
            )}
          </Stack>
          {article.description && (
            <Box marginBottom={2}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: articleContentSanitized(article.description),
                }}
              />
            </Box>
          )} */}
          <Box
            sx={{
              lineHeight: 1.5,
              "& a": {
                color: "primary.main",
              },
            }}
          >
            <ArticleContentHTML
              content={articleContentSanitized(getArticleContent())}
            />
          </Box>
          {article.approval_code && (
            <>
              <Box
                sx={{
                  width: "100%",
                  height: "0.5px",
                  background: "rgba(255, 255, 255, 0.125)",
                }}
              />
              <Typography variant="body2">{article.approval_code}</Typography>
            </>
          )}
        </Stack>
      </Box>
    );
  };

  return pageContent ? (
    article !== undefined ? (
      <Container
        ref={topRef}
        maxWidth="xl"
        sx={{
          position: "relative",
          paddingTop: 3,
          paddingBottom: {
            xs: "80px",
            sm: "170px",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // height: "100%",
          minHeight: "100%",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={8}>
            <PageContent article={article} />
            {/* <PageScrollTop /> */}
          </Grid>
        </Grid>
      </Container>
    ) : (
      <Error404 />
    )
  ) : null;
};

export default PageInfo;
