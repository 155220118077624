import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import axios from "axios";
import config from "utils/config";
import { useQueryClient } from "@tanstack/react-query";

const CheckHCMID = (props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const hcmid =
    searchParams.get("hcmid") ||
    searchParams.get("HCMID") ||
    searchParams.get("azid") ||
    searchParams.get("AZID") ||
    searchParams.get("hcpid") ||
    searchParams.get("HCPID");

  const signIn = useSignIn();
  const queryClient = useQueryClient();

  const [checkForHCMID, setCheckforHCMID] = useState(false);

  // const delay = (time) => {
  //   return new Promise((resolve) => setTimeout(resolve, time));
  // };

  useEffect(() => {
    const checkForHCMID = async () => {
      if (!hcmid) {
        setCheckforHCMID(true);
      } else {
        try {
          const response = await axios.get(
            `${config.API_URL}/hcmid-login?hcmid=${hcmid}`
          );
          // await delay(5000);
          if (response.data.message === "Login successful") {
            try {
              const responseUserProfile = await axios.get(
                `${config.API_URL}/get-profile-settings`,
                {
                  headers: {
                    Authorization: `Bearer ${response.data.token}`,
                  },
                }
              );
              signIn({
                token: response.data.token,
                tokenType: "Bearer",
                authState: responseUserProfile.data,
                expiresIn: 30 * 24 * 60, // minutes
              });
              queryClient.clear();
              // console.log(location);
              // await delay(1000);
              setCheckforHCMID(true);

              // Extract the current query parameters
              const currentQueryParams = new URLSearchParams(location.search);
              // Remove the 'auth' parameter if it exists
              // currentQueryParams.delete("hcmid");
              // currentQueryParams.delete("HCMID");
              // currentQueryParams.delete("azid");
              // currentQueryParams.delete("AZID");
              // currentQueryParams.delete("hcpid");
              // currentQueryParams.delete("HCPID");
              // Construct the new URL with the updated query parameters
              const newUrl = `${
                location.pathname
              }?${currentQueryParams.toString()}`;

              navigate(newUrl);
              // console.log(response);
            } catch (err) {
              console.error(err);
              setCheckforHCMID(true);
              navigate("/login");
            }
          } else {
            setCheckforHCMID(true);
            navigate("/login");
          }
          // console.log(response.data);
        } catch (err) {
          // console.log(err);
          setCheckforHCMID(true);
          navigate("/login");
        }
      }
    };
    checkForHCMID();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return checkForHCMID ? <>{props.children}</> : <></>;
};

export default CheckHCMID;
